.outerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: wheat;
  -webkit-touch-callout: none;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #FFFFFF;
  border-radius: 8px;
  height: 70%;
  width: 45%;
}

.overlay {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.overlayFirst {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.overlayButtonFirst {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.overlay {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 80%;
  border-radius: 10px;
  padding: 20px 15px 30px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border: 1px solid #DEDEDE;
  z-index: 2;
  max-width: 500px;
}

.overlayButton {
  height: 100%
}

.overlayMicButton {
  height: 90%
}

.firstmodal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  padding: 20px 15px 30px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  z-index: 2;
  max-width: 500px;

}

/* 
@media (min-width: 320px) and (max-width: 480px) {
  .outerContainer {}

  .container {}
} */

@media (max-width:600px) {
  .outerContainer {
    height: calc(var(--vh, 1svh) * 100);
  }

  .container {
    width: 100%;
    height: calc(var(--vh, 1svh) * 100);
  }

  .overlay {
    height: calc(var(--vh, 1svh) * 100);

  }

  .overlayFirst {
    height: calc(var(--vh, 1svh) * 100);
  }

  .overlayButtonFirst {
    height: calc(var(--vh, 1svh) * 100 - 75px);
  }

  .overlayButton {
    height: calc(var(--vh, 1svh) * 100);
  }

  .overlayMicButton {
    height: calc(var(--vh, 1svh) * 90);
  }
}

@media (min-width: 600px) and (max-width: 1200px) {
  .outerContainer {
    background-color: wheat;
    height: inherit;
  }

  .container {
    width: 50%;
  }

  .overlayFirst {

    top: 15%;
    left: 25%;
    width: 50%;
    height: 70%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }


  .overlayButtonFirst {
    top: 15%;
    left: 25%;
    width: 50%;
    height: calc(70% - 75px);
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }

}

@media (min-width: 1201px) {
  .outerContainer {
    background-color: wheat;
    height: inherit;
  }

  .container {
    width: 50%;
  }

  .overlayFirst {
    top: 15%;
    left: 25%;
    width: 50%;
    height: 70%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }

  .overlayButtonFirst {
    top: 15%;
    left: 25%;
    width: 50%;
    height: calc(70% - 75px);
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }
}

.dot-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 60px;
}

.dot {
  width: 10px;
  height: 10px;
  background-color: #DEDEDE;
  border-radius: 50%;
  animation: bounce 1.5s infinite;
}

.dot:nth-child(1) {
  animation-delay: 0s;
}

.dot:nth-child(2) {
  animation-delay: 0.3s;
}

.dot:nth-child(3) {
  animation-delay: 0.6s;
}

@keyframes bounce {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-5px);
  }
}